/* font-family: "Lexend", sans-serif; */
/* font-family: "GeneralSans", sans-serif; */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "GeneralSans", "Monument Extended", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f4f3f8;
  font-family: "GeneralSans", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.title {
  letter-spacing: 0px;
  font-family: "Monument Extended", "sans-serif";
  color: black;
  font-weight: 800;
  line-height: 120%;
}
